import Vue from "vue";
// @ts-ignore
import CreateTabs from "@/views/Admin/RequestCampaign/Create/CreateTab.vue";
// @ts-ignore
import Overview from "@/views/Admin/RequestCampaign/Create/Overview.vue";
// @ts-ignore
import AssociatedCreative from "@/views/Admin/RequestCampaign/Dependencies/Creatives/AssociatedCreative.vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { isEmpty } from "lodash";
//@ts-ignore
import { getPermisionCampaignCpi } from "@/utils/permissionResolve";

const BUY_MODEL_CPI = "ext_buy_model_cpi";

export default Vue.extend({
    name: "LogicCreateRequestCampaign",
    props:{},
    components:{ 
        CreateTabs, 
        Overview, 
        Alertize,
        AssociatedCreative,
    },
    data: () => ({
        title: "Create",
        currentTab: 0,
        items: [],
        requestCampaign: {}
    }),
    created(){},
    mounted(){
        this.$nextTick(async () => {
            await this.dispatchAccount();
        });
    },
    computed:{

        ...mapGetters("profile", ["getAbility"]),

        getItems() {
			return this.updateItems;
		},

		updateItems() {
			this.items = [
				{
					key: 0,
					tab: "Overview",
					disabled: false
				},
				{
					key: 1,
					tab: "Creatives",
					disabled: this.enabledTabCreative
				}
			];
			return this.items;
		},

        hasID() {
			return !!this.$route.params.id;
		},

        getID() {
			return parseInt(this.$route.params.id);
		},

		isEdit() {
			return !!this.hasID;
		},

        getAccountData() {
			return this.$store.state.account.account;
		},

        getCampaignRequest(){
            return this.requestCampaign;
        },

        enabledTabCreative(){
            if(this.canToCreatives){
                return !(this.isBuyModelCPI && (this.isEdit || this.getCampaignRequest?.id ))
            }else{
                return true;
            }
        },

        isBuyModelCPI(){
            if(isEmpty(this.getCampaignRequest)) return false;
            return this.getCampaignRequest?.buy_model?.extra == BUY_MODEL_CPI;
        },

        canToCreatives(){
			return this.getAbility.can(this.getPermission.actions.index_create, this.getPermission.subject);
		},

        getPermission(){
			return getPermisionCampaignCpi();
		}
    },
    methods:{

        ...mapActions("account", [
            "getAccount"
        ]),

        updateSelectedTabIndex(index: number = 0) {
            this.currentTab = index;
        },

        currentTabSelected(item: String, selected: String) {
            return item === selected;
        },

        async setEntity(entity: any){
            this.requestCampaign = entity;
        },

        async dispatchAccount() {
			return await this.getAccount();
		},
    }
});